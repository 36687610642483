/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthRequest,
  Currency,
  CurrencyExchangeRateResponseModel,
  DepositAccountListResponse,
  FinanceAccount,
  FinanceCategory,
  FinanceCategoryListResponse,
  FinanceGroup,
  HTTPValidationError,
  Income,
  IncomeAndExpensesStatisticsSchema,
  IncomeListResponse,
  Token,
  Transaction,
  TransactionTag,
  User,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get user token by username and password
   *
   * @tags auth
   * @name Authenticate
   * @summary Authenticate
   * @request POST:/api/auth/
   */
  authenticate = (data: AuthRequest, params: RequestParams = {}) =>
    this.http.request<Token, void | HTTPValidationError>({
      path: `/api/auth/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags currency-exchange-rates
   * @name GetListOfCurrencyExchangeRatesForSpecifiedPair
   * @summary Get List Of Currency Exchange Rates For Specified Pair
   * @request GET:/api/finance/currency-exchange-rates/get-list
   */
  getListOfCurrencyExchangeRatesForSpecifiedPair = (
    query: {
      /** An enumeration. */
      from_currency: Currency;
      /** An enumeration. */
      to_currency: Currency;
      /**
       * Page
       * @default 1
       */
      page?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<CurrencyExchangeRateResponseModel, void | HTTPValidationError>({
      path: `/api/finance/currency-exchange-rates/get-list`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name CreateFinanceAccount
   * @summary Create Finance Account
   * @request POST:/api/finance/accounts/create
   */
  createFinanceAccount = (data: FinanceAccount, params: RequestParams = {}) =>
    this.http.request<FinanceAccount, void | HTTPValidationError>({
      path: `/api/finance/accounts/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name GetListOfAccounts
   * @summary Get List Of Accounts
   * @request GET:/api/finance/accounts/get-list
   */
  getListOfAccounts = (params: RequestParams = {}) =>
    this.http.request<FinanceAccount[], void>({
      path: `/api/finance/accounts/get-list`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name DeleteFinanceAccount
   * @summary Delete Finance Account
   * @request DELETE:/api/finance/accounts/delete
   */
  deleteFinanceAccount = (data: FinanceAccount, params: RequestParams = {}) =>
    this.http.request<FinanceAccount, void | HTTPValidationError>({
      path: `/api/finance/accounts/delete`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name UpdateFinanceAccount
   * @summary Update Finance Account
   * @request PUT:/api/finance/accounts/update
   */
  updateFinanceAccount = (data: FinanceAccount, params: RequestParams = {}) =>
    this.http.request<FinanceAccount, void | HTTPValidationError>({
      path: `/api/finance/accounts/update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-categories
   * @name CreateFinanceCategory
   * @summary Create Finance Category
   * @request POST:/api/finance/categories/create
   */
  createFinanceCategory = (data: FinanceCategory, params: RequestParams = {}) =>
    this.http.request<FinanceCategory, void | HTTPValidationError>({
      path: `/api/finance/categories/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-categories
   * @name GetListOfFinanceCategories
   * @summary Get List Of Finance Categories
   * @request GET:/api/finance/categories/get-list
   */
  getListOfFinanceCategories = (params: RequestParams = {}) =>
    this.http.request<FinanceCategoryListResponse, void>({
      path: `/api/finance/categories/get-list`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-categories
   * @name DeleteFinanceCategory
   * @summary Delete Finance Category
   * @request DELETE:/api/finance/categories/delete
   */
  deleteFinanceCategory = (data: FinanceCategory, params: RequestParams = {}) =>
    this.http.request<FinanceCategory, void | HTTPValidationError>({
      path: `/api/finance/categories/delete`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-categories
   * @name UpdateFinanceCategory
   * @summary Update Finance Category
   * @request PUT:/api/finance/categories/update
   */
  updateFinanceCategory = (data: FinanceCategory, params: RequestParams = {}) =>
    this.http.request<FinanceCategory, void | HTTPValidationError>({
      path: `/api/finance/categories/update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-groups
   * @name GetListOfFinanceGroups
   * @summary Get List Of Finance Groups
   * @request GET:/api/finance/groups/get-list
   */
  getListOfFinanceGroups = (
    query: {
      /** Offset */
      offset: number;
      /** Limit */
      limit: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<FinanceGroup[], void | HTTPValidationError>({
      path: `/api/finance/groups/get-list`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-groups
   * @name GetDetailsForFinanceGroup
   * @summary Get Details For Finance Group
   * @request GET:/api/finance/groups/get-details
   */
  getDetailsForFinanceGroup = (
    query: {
      /** Finance Group Id */
      finance_group_id: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<FinanceGroup, void | HTTPValidationError>({
      path: `/api/finance/groups/get-details`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags finance-groups
   * @name GetIncomeAndExpensesStatisticsForFinanceGroup
   * @summary Get Income And Expenses Statistics For Finance Group
   * @request GET:/api/finance/groups/income-expenses-statistics
   */
  getIncomeAndExpensesStatisticsForFinanceGroup = (
    query: {
      /** Period */
      period: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<IncomeAndExpensesStatisticsSchema, void | HTTPValidationError>({
      path: `/api/finance/groups/income-expenses-statistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investment
   * @name GetListOfDepositAccounts
   * @summary Get List Of Deposit Accounts
   * @request GET:/api/finance/investment/deposit-accounts/get-list
   */
  getListOfDepositAccounts = (params: RequestParams = {}) =>
    this.http.request<DepositAccountListResponse, void>({
      path: `/api/finance/investment/deposit-accounts/get-list`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags income
   * @name CreateIncome
   * @summary Create Income
   * @request POST:/api/finance/income/create
   */
  createIncome = (data: Income, params: RequestParams = {}) =>
    this.http.request<Income, void | HTTPValidationError>({
      path: `/api/finance/income/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags income
   * @name GetListOfIncome
   * @summary Get List Of Income
   * @request GET:/api/finance/income/get-list
   */
  getListOfIncome = (params: RequestParams = {}) =>
    this.http.request<IncomeListResponse, void>({
      path: `/api/finance/income/get-list`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags income
   * @name DeleteIncome
   * @summary Delete Income
   * @request DELETE:/api/finance/income/delete
   */
  deleteIncome = (data: Income, params: RequestParams = {}) =>
    this.http.request<Income, void | HTTPValidationError>({
      path: `/api/finance/income/delete`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags income
   * @name UpdateIncome
   * @summary Update Income
   * @request PUT:/api/finance/income/update
   */
  updateIncome = (data: Income, params: RequestParams = {}) =>
    this.http.request<Income, void | HTTPValidationError>({
      path: `/api/finance/income/update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name CreateTransaction
   * @summary Create Transaction
   * @request POST:/api/finance/transactions/create
   */
  createTransaction = (data: Transaction, params: RequestParams = {}) =>
    this.http.request<Transaction, void | HTTPValidationError>({
      path: `/api/finance/transactions/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name GetListOfTransactions
   * @summary Get List Of Transactions
   * @request GET:/api/finance/transactions/get-list
   */
  getListOfTransactions = (
    query: {
      /** Offset */
      offset: number;
      /** Limit */
      limit: number;
      /** Query */
      query?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<Transaction[], void | HTTPValidationError>({
      path: `/api/finance/transactions/get-list`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name DeleteTransaction
   * @summary Delete Transaction
   * @request DELETE:/api/finance/transactions/delete
   */
  deleteTransaction = (data: Transaction, params: RequestParams = {}) =>
    this.http.request<Transaction, void | HTTPValidationError>({
      path: `/api/finance/transactions/delete`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name UpdateTransaction
   * @summary Update Transaction
   * @request PUT:/api/finance/transactions/update
   */
  updateTransaction = (data: Transaction, params: RequestParams = {}) =>
    this.http.request<Transaction, void | HTTPValidationError>({
      path: `/api/finance/transactions/update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name GetListOfUniqueTransactionTags
   * @summary Get List Of Unique Transaction Tags
   * @request GET:/api/finance/transactions/get-tags
   */
  getListOfUniqueTransactionTags = (params: RequestParams = {}) =>
    this.http.request<TransactionTag[], void>({
      path: `/api/finance/transactions/get-tags`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags transactions
   * @name GetListOfAutocompleteSuggestions
   * @summary Get List Of Autocomplete Suggestions
   * @request GET:/api/finance/transactions/get-autocomplete-suggestions
   */
  getListOfAutocompleteSuggestions = (
    query: {
      /** Query */
      query: string;
      /** Account Id */
      account_id: string;
      /** Category Id */
      category_id: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<string[], void | HTTPValidationError>({
      path: `/api/finance/transactions/get-autocomplete-suggestions`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name GetAuthUserInfo
   * @summary Get Auth User Info
   * @request GET:/api/users/me/
   */
  getAuthUserInfo = (params: RequestParams = {}) =>
    this.http.request<User, void>({
      path: `/api/users/me/`,
      method: "GET",
      format: "json",
      ...params,
    });
}
