import dayjs from "utils/dayjs";

enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

export default class Logger {
  public static info(message: string): void {
    Logger.log(message, LogLevel.INFO);
  }

  public static warn(message: string): void {
    Logger.log(message, LogLevel.WARN);
  }

  public static error(message: string): void {
    Logger.log(message, LogLevel.ERROR);
  }

  public static debug(message: string): void {
    Logger.log(message, LogLevel.DEBUG);
  }

  public static exception(error: Error): void {
    Logger.log(error.message, LogLevel.ERROR);
  }

  private static log(message: string, type: LogLevel): void {
    // logs should be enabled only in development mode
    if (import.meta.env.VITE_ENV !== "LOCAL") {
      return;
    }

    const printMessage = `[${dayjs().format("YYYY-MM-DD HH:mm:ss")}] [${type}] ${message}`;

    /* eslint-disable no-console */
    switch (type) {
      case LogLevel.DEBUG:
        console.debug(printMessage);
        break;
      case LogLevel.INFO:
        console.info(printMessage);
        break;
      case LogLevel.WARN:
        console.warn(printMessage);
        break;
      case LogLevel.ERROR:
        console.error(printMessage);
        break;
      default:
        console.log(printMessage);
        break;
    }
    /* eslint-enable no-console */
  }
}
