/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AuthRequest */
export interface AuthRequest {
  /** Username */
  username: string;
  /** Password */
  password: string;
}

/**
 * Currency
 * An enumeration.
 */
export enum Currency {
  EUR = "EUR",
  HKD = "HKD",
  KRW = "KRW",
  RUB = "RUB",
  THB = "THB",
  USD = "USD",
}

/** CurrencyExchangeRate */
export interface CurrencyExchangeRate {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** An enumeration. */
  from_currency: Currency;
  /** An enumeration. */
  to_currency: Currency;
  /** Rate */
  rate: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
}

/** CurrencyExchangeRateResponseModel */
export interface CurrencyExchangeRateResponseModel {
  /** Items */
  items: CurrencyExchangeRate[];
  /** Page */
  page: number;
  /** Per Page */
  per_page: number;
  /** Count */
  count: number;
}

/** DepositAccount */
export interface DepositAccount {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Name */
  name: string;
  /** Account Id */
  account_id: string;
  account?: FinanceAccount;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
  /** Percent */
  percent: number;
  /** Amount */
  amount: number;
  /** An enumeration. */
  currency: Currency;
  /** Approximate Total Income */
  approximate_total_income?: number;
}

/** DepositAccountListResponse */
export interface DepositAccountListResponse {
  /** Accounts */
  accounts: DepositAccount[];
  /**
   * MoneyStat is a model for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies.
   */
  income_stat: MoneyStat;
}

/** FinanceAccount */
export interface FinanceAccount {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Name */
  name: string;
  /** An enumeration. */
  currency: Currency;
  /** An enumeration. */
  account_type: FinanceAccountType;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Display Level
   * @default 0
   */
  display_level?: number;
  /**
   * Balance
   * @default 0
   */
  balance?: number;
  /**
   * Order
   * @default 0
   */
  order?: number;
}

/**
 * FinanceAccountType
 * An enumeration.
 */
export enum FinanceAccountType {
  BANK = "BANK",
  CASH = "CASH",
  INVESTING = "INVESTING",
  OTHER = "OTHER",
}

/** FinanceCategory */
export interface FinanceCategory {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Name */
  name: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Order
   * @default 0
   */
  order?: number;
  /** An enumeration. */
  category_type?: FinanceCategoryType;
  /**
   * Amount
   * @default 0
   */
  amount?: number;
}

/** FinanceCategoryListResponse */
export interface FinanceCategoryListResponse {
  /** Categories */
  categories: FinanceCategory[];
  /**
   * MoneyStat is a model for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies.
   */
  monthly_spending_stat: MoneyStat;
}

/**
 * FinanceCategoryType
 * An enumeration.
 */
export enum FinanceCategoryType {
  PRODUCTS = "PRODUCTS",
  RESTAURANTS = "RESTAURANTS",
  TRANSPORT = "TRANSPORT",
  SERVICES = "SERVICES",
  ENTERTAINMENT = "ENTERTAINMENT",
  HOUSE = "HOUSE",
  SHOPPING = "SHOPPING",
  OTHER = "OTHER",
}

/** FinanceGroup */
export interface FinanceGroup {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Name */
  name: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date?: string;
  /**
   * End Date
   * @format date-time
   */
  end_date?: string;
  /** An enumeration. */
  group_type: FinanceGroupType;
  /**
   * Sort Field
   * @format date-time
   */
  sort_field?: string;
  /** Associated Transaction Tags */
  associated_transaction_tags?: TransactionTag[];
  statistics?: FinanceGroupStatisticsSchema;
}

/** FinanceGroupStatisticsSchema */
export interface FinanceGroupStatisticsSchema {
  /**
   * MoneyStat is a model for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies.
   */
  all: MoneyStat;
  /** Categories */
  categories: Record<string, MoneyStat>;
  /** Comments */
  comments: Record<string, MoneyStat>;
  /** Tags */
  tags: Record<string, MoneyStat>;
  /** Income */
  income: Record<string, MoneyStat>;
  /**
   * First Transaction Date
   * @format date-time
   */
  first_transaction_date: string;
  /**
   * Last Transaction Date
   * @format date-time
   */
  last_transaction_date: string;
}

/**
 * FinanceGroupType
 * An enumeration.
 */
export enum FinanceGroupType {
  MONTHLY_EXPENSES = "MONTHLY_EXPENSES",
  MONTHLY_INCOME = "MONTHLY_INCOME",
  PERIODICAL = "PERIODICAL",
  DATA_BASED = "DATA_BASED",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Income */
export interface Income {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Associated Account Id */
  associated_account_id: string;
  associated_account?: FinanceAccount;
  /**
   * Earnings
   * @default 0
   */
  earnings?: number;
  /** An enumeration. */
  earnings_currency?: Currency;
}

/** IncomeAndExpensesStatisticsSchema */
export interface IncomeAndExpensesStatisticsSchema {
  /** Income */
  income: object[];
  /** Expenses */
  expenses: object[];
}

/** IncomeListResponse */
export interface IncomeListResponse {
  /** Income List */
  income_list: Income[];
  /**
   * MoneyStat is a model for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies.
   */
  monthly_earnings_stat: MoneyStat;
}

/**
 * MoneyStat
 * MoneyStat is a model for the money statistic.
 * It contains approximate values of earning/spending in default currency.
 * And it contains exact earning/spending amount in all currencies.
 */
export interface MoneyStat {
  /** Default Currency Amount */
  default_currency_amount: number;
  /** All Currencies Amount */
  all_currencies_amount: Record<string, number>;
}

/** Token */
export interface Token {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
}

/** Transaction */
export interface Transaction {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Amount */
  amount: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** From Income Id */
  from_income_id?: string;
  from_income?: Income;
  /** Account Id */
  account_id: string;
  account?: FinanceAccount;
  /** Category Id */
  category_id?: string;
  category?: FinanceCategory;
  /** An enumeration. */
  type: TransactionType;
  /** An enumeration. */
  currency: Currency;
  /** Tags */
  tags?: TransactionTag[];
  /** Comment */
  comment?: string;
  /** Equivalent Amount In Usd */
  equivalent_amount_in_usd?: number;
  /** To Account Id */
  to_account_id?: string;
  to_account?: FinanceAccount;
  /** An enumeration. */
  to_currency?: Currency;
  /** To Amount */
  to_amount?: number;
}

/** TransactionTag */
export interface TransactionTag {
  /** Name */
  name: string;
}

/**
 * TransactionType
 * An enumeration.
 */
export enum TransactionType {
  REGULAR = "REGULAR",
  INCOME = "INCOME",
  TRANSFER = "TRANSFER",
}

/** User */
export interface User {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /** Username */
  username: string;
  /** Email */
  email: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}
