import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FinanceGroup, FinanceGroupType, MoneyStat } from "client/data-contracts";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import { selectFinanceCategories } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { loadIncome, selectIncomeList } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { isDefined, Nullish } from "utils/base";
import getById from "utils/crud";

export default function FinanceGroupsDetails(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const params = useParams();
  const financeCategories = useAppSelector(selectFinanceCategories);
  const incomeList = useAppSelector(selectIncomeList);
  const [financeGroup, setFinanceGroup] = useState<Nullish<FinanceGroup>>(null);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getDetailsForFinanceGroup({
        finance_group_id: params.financeGroupId as string,
      });
      setFinanceGroup(response.data);
    })();
  }, [params.financeGroupId]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfIncome();
      dispatch(loadIncome(response.data));
    })();
  }, [dispatch]);

  function buildStatBlock(statistics: Record<string, MoneyStat> | undefined, sectionName: string) {
    if (!isDefined(statistics) || financeCategories.length === 0) {
      return <span />;
    }

    let innerSectionName = sectionName;
    if (sectionName === "from_income_id") {
      innerSectionName = "income";
    }

    const sortableArray = Object.entries(statistics);
    const sortedArray = sortableArray.sort(
      ([, a], [, b]) => b.default_currency_amount - a.default_currency_amount
    );
    const sortedStatistics = Object.fromEntries(sortedArray);

    const tableContent = Object.keys(sortedStatistics).map((key) => {
      if (!isDefined(sortedStatistics)) {
        return <tr key={key} />;
      }

      const moneyStat = sortedStatistics[key];

      let name = "";
      if (innerSectionName === "category" && key) {
        const category = getById(financeCategories, key);
        name = category?.name;
      } else if (innerSectionName === "income" && key) {
        const income = getById(incomeList, key);
        name = income?.name;
      } else {
        name = key;
      }

      return (
        <tr key={key}>
          <td>{name || "-"}</td>
          <td>
            <MoneyStatDisplay roundBigNumber showOnlyTotal={false} displayPrefix={false} stat={moneyStat} />
          </td>
        </tr>
      );
    });

    return (
      <>
        <h4 data-test={`finance-group-details-section-${innerSectionName}`}>By {innerSectionName}</h4>
        <table className="table table-bordered table-striped">
          <tbody>{tableContent}</tbody>
        </table>
      </>
    );
  }

  return (
    <>
      {isDefined(financeGroup) && incomeList.length ? (
        <div>
          <h3 data-test="finance-group-details-name">{financeGroup.name}</h3>
          <p>
            <MoneyStatDisplay
              roundBigNumber={false}
              showOnlyTotal={false}
              displayPrefix
              stat={financeGroup.statistics?.all}
            />
          </p>
          <div className="row">
            {financeGroup.group_type !== FinanceGroupType.MONTHLY_INCOME && (
              <div className="col-lg-6">
                {buildStatBlock(financeGroup.statistics?.categories, "category")}
                {buildStatBlock(financeGroup.statistics?.tags, "tags")}
              </div>
            )}
            <div className="col-lg-6">{buildStatBlock(financeGroup.statistics?.comments, "comment")}</div>
            {financeGroup.group_type === FinanceGroupType.MONTHLY_INCOME && (
              <div className="col-lg-6">
                {buildStatBlock(financeGroup.statistics?.income, "from_income_id")}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
