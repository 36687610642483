import "components/Finances/Common/CircleItem.scss";

import React from "react";
import { GrEdit } from "react-icons/gr";
import { Currency } from "client/data-contracts";
import { isDefined, Nullish } from "utils/base";
import { displayMoneyWithCurrency } from "utils/finances";

interface CircleItemProps {
  name: string;
  icon: React.ReactNode;
  amount: Nullish<number>;
  currency: Nullish<Currency>;
  // eslint-disable-next-line react/require-default-props
  editAction?: () => void;
}

export default function CircleItem({
  name,
  icon,
  amount,
  currency,
  editAction = () => {},
}: CircleItemProps): React.JSX.Element {
  let amountDisplay = "";
  if (isDefined(amount) && isDefined(currency)) {
    amountDisplay = displayMoneyWithCurrency(amount, currency);
  }

  return (
    <div data-test="circle-item" className="circle-item">
      <div data-test="circle-item-title" title={name} className="title">
        <span className="title-string">{name}</span>
        {editAction && (
          <span
            data-test="circle-item-edit"
            className="circle-item-edit"
            onClick={editAction}
            aria-hidden="true"
          >
            <GrEdit />
          </span>
        )}
      </div>
      <div className="icon-block">{icon}</div>
      <div data-test="circle-item-money" className="money" title={amountDisplay}>
        {amountDisplay}
      </div>
    </div>
  );
}
