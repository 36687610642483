import "components/App/App.scss";

import React from "react";
import { DndProvider } from "react-dnd";
import { Preview } from "react-dnd-preview";
import { TouchBackend } from "react-dnd-touch-backend";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "components/App/Parts/Footer";
import Header from "components/App/Parts/Header";
import { generatePreview } from "components/Common/DragAndDrop";
import Finances from "components/Finances/Finances";
import Home from "components/Home";
import Login from "components/Login/Login";
import { useToken } from "utils/token";

export default function App(): React.JSX.Element {
  const [token, setToken] = useToken();

  let content;
  if (!token) {
    content = <Login setToken={setToken} />;
  } else {
    content = (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/finances/*"
          element={
            <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
              <Preview generator={generatePreview} />
              <Finances />
            </DndProvider>
          }
        />
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      <Header setToken={setToken} />
      <main role="main" className="container" data-test="main-container">
        {content}
      </main>
      <Footer />
    </BrowserRouter>
  );
}
