import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import CurrencyExchangeRates from "components/Finances/CurrencyExchangeRates/CurrencyExchangeRates";
import CircleFinanceAccountsList from "components/Finances/FinanceAccounts/CircleFinanceAccountsList";
import FinanceAccounts from "components/Finances/FinanceAccounts/FinanceAccounts";
import { loadAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import FinanceCategories from "components/Finances/FinanceCategories/FinanceCategories";
import { loadFinanceCategories } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import FinanceGroupsDetails from "components/Finances/FinanceGroups/FinanceGroupsDetails";
import FinanceGroupsList from "components/Finances/FinanceGroups/FinanceGroupsList";
import FinancesMainNavigation from "components/Finances/FinancesMainNavigation";
import IncomeMain from "components/Finances/Income/IncomeMain";
import TransactionsAdd from "components/Finances/Transactions/TransactionsAdd";
import TransactionsEdit from "components/Finances/Transactions/TransactionsEdit";
import TransactionsList from "components/Finances/Transactions/TransactionsList";
import FinanceCategoriesEdit from "components/Finances/FinanceCategories/FinanceCategoriesEdit";
import { loadTransactions } from "components/Finances/Transactions/TransactionsSlice";
import { useAppDispatch } from "hooks";
import api from "utils/api";
import { ConfirmDeleteModalContext, ConfirmDeleteModalInputProps, NavOption } from "utils/base";
import { DEFAULT_TRANSACTIONS_LIMIT } from "utils/finances";
import Investments from "components/Finances/Investments/Investments";
import FinanceCategoriesAdd from "components/Finances/FinanceCategories/FinanceCategoriesAdd";

const mainPageLinks = ["/finances/transactions", "/finances/finance-categories"];

export default function Finances(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [navOptions, setNavOptions] = useState([
    { name: "Main", isActive: false, link: "" } as NavOption,
    { name: "Accounts", isActive: false, link: `/finances/accounts` } as NavOption,
    { name: "Income", isActive: false, link: `/finances/income` } as NavOption,
    { name: "Stat", isActive: false, link: `/finances/finance-groups` } as NavOption,
    { name: "Investments", isActive: false, link: `/finances/investments` } as NavOption,
    { name: "Currency", isActive: false, link: `/finances/currency-exchange-rates` } as NavOption,
  ]);

  // handle isActive tag based on current location
  useEffect(() => {
    setNavOptions((prevState) => {
      const tempState = [...prevState];
      const basePath = location.pathname;
      tempState.forEach((item) => {
        const tempItem = item;
        if (item.link === "") {
          tempItem.isActive =
            basePath === "/finances" || mainPageLinks.find((link) => basePath.startsWith(link)) !== undefined;
        } else {
          tempItem.isActive = basePath.startsWith(item.link);
        }
        return tempItem;
      });
      return tempState;
    });
  }, [location]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfAccounts({});
      dispatch(loadAccounts(response.data));
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfTransactions({
        offset: 0,
        limit: DEFAULT_TRANSACTIONS_LIMIT,
      });
      dispatch(loadTransactions(response.data));
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfFinanceCategories();
      dispatch(loadFinanceCategories(response.data));
    })();
  }, [dispatch]);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [confirmDeleteModalInputProps, setConfirmDeleteModalInputProps] =
    useState<ConfirmDeleteModalInputProps>({
      deleteAction: () => {},
      toDeleteName: "",
      toDeleteId: "",
    } as ConfirmDeleteModalInputProps);

  return (
    /* eslint-disable react/jsx-no-constructed-context-values */
    <ConfirmDeleteModalContext.Provider
      value={{
        showConfirmDeleteModal,
        setShowConfirmDeleteModal,
        confirmDeleteModalInputProps,
        setConfirmDeleteModalInputProps,
      }}
    >
      {/* eslint-enable */}
      <div className="row mb-1 mb-md-3">
        <div className="col">
          <FinancesMainNavigation options={navOptions} />
        </div>
      </div>
      <Routes>
        <Route
          path=""
          element={
            <div>
              <div className="row">
                <div className="col">
                  <CircleFinanceAccountsList />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FinanceCategories />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TransactionsList />
                </div>
              </div>
            </div>
          }
        />
        <Route path="/investments" element={<Investments />} />
        <Route path="/currency-exchange-rates" element={<CurrencyExchangeRates />} />
        <Route path="/finance-groups" element={<FinanceGroupsList />} />
        <Route path="/finance-groups/:financeGroupId" element={<FinanceGroupsDetails />} />
        <Route path="accounts" element={<FinanceAccounts />} />
        <Route path="income" element={<IncomeMain />} />
        <Route path="/transactions/edit/:type/:transactionId" element={<TransactionsEdit />} />
        <Route path="/finance-categories/edit/:financeCategoryId" element={<FinanceCategoriesEdit />} />
        <Route path="/finance-categories/add" element={<FinanceCategoriesAdd />} />
        <Route path="/transactions/:type/:fromAccountId/:toEntityId" element={<TransactionsAdd />} />
      </Routes>
      <ConfirmDeleteModal
        showModal={showConfirmDeleteModal}
        setShowModal={setShowConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        inputProps={confirmDeleteModalInputProps}
      />
    </ConfirmDeleteModalContext.Provider>
  );
}
