import { Api } from "client/Api";
import { HttpClient } from "client/http-client";
import { getToken } from "utils/token";

const httpClient = new HttpClient<string>({
  securityWorker: () => {
    const tokenObj = getToken();
    return tokenObj
      ? {
          headers: {
            Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`,
          },
        }
      : {};
  },
  ...{ baseURL: import.meta.env.VITE_API_HOST, secure: true },
});

const apiInstance = new Api(httpClient);

const api = {
  http: httpClient,
  users: apiInstance,
  auth: apiInstance,
  finance: apiInstance,
};

export default api;
