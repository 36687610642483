import "components/Finances/CurrencyExchangeRates/CurrencyExchangeRates.scss";

import React, { useEffect, useState } from "react";
import { DepositAccount, MoneyStat } from "client/data-contracts";
import api from "utils/api";
import { displayDate } from "utils/date";
import { displayMoneyWithCurrency } from "utils/finances";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import { Nullish } from "utils/base";

export default function DepositAccounts(): React.JSX.Element {
  const [deposits, setDeposits] = useState<DepositAccount[]>([]);
  const [incomeStat, setIncomeStat] = useState<Nullish<MoneyStat>>(null);
  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfDepositAccounts();
      setDeposits(response.data.accounts);
      setIncomeStat(response.data.income_stat);
    })();
  }, [setDeposits, setIncomeStat]);

  const rows = deposits.map((item) => {
    return (
      <tr key={item.id}>
        <td>{item.account?.name}</td>
        <td>{item.name}</td>
        <td>{displayMoneyWithCurrency(item.amount, item.currency)}</td>
        <td>{item.percent}%</td>
        <td>{displayDate(item.start_date)}</td>
        <td>{displayDate(item.end_date)}</td>
        <td>~{displayMoneyWithCurrency(item.approximate_total_income || 0, item.currency)}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-4 d-flex">
          <h3>Deposit accounts</h3>
        </div>
        <div className="col-12 col-lg-8 text-secondary small text-lg-end text-start pt-3">
          {incomeStat && (
            <>
              Estimated income:
              <MoneyStatDisplay
                roundBigNumber={false}
                showOnlyTotal={false}
                displayPrefix={false}
                stat={incomeStat}
              />
            </>
          )}
        </div>
      </div>

      <table
        data-test="deposit-accounts-table"
        className="mt-2 table table-responsive table-bordered table-striped table-sm"
      >
        <thead>
          <tr>
            <th>Account</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Percent</th>
            <th>From</th>
            <th>To</th>
            <th>Income</th>
          </tr>
        </thead>
        <tbody>
          {deposits.length ? (
            rows
          ) : (
            <tr>
              <td colSpan={7}>No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
