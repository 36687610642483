import "components/Finances/CurrencyExchangeRates/CurrencyExchangeRates.scss";

import React, { useEffect, useState } from "react";
import { Currency, CurrencyExchangeRate } from "client/data-contracts";
import api from "utils/api";
import { Nullish } from "utils/base";
import { displayDate } from "utils/date";
import { DEFAULT_CURRENCY } from "utils/finances";

const CURRENCY_PAIRS = Object.keys(Currency).filter((item) => item !== DEFAULT_CURRENCY);

const formatDelta = (currentItem: CurrencyExchangeRate, previousItem: CurrencyExchangeRate) => {
  // this round is needed to avoid "-0.00" value displaying
  const delta = Math.round((currentItem.rate - previousItem.rate + Number.EPSILON) * 100) / 100;
  let badgeClass = delta > 0 ? "text-bg-success" : "text-bg-danger";

  // for big deltas show one decimal place to fit in the badge
  let deltaStringDigits = 2;
  if (delta >= 10 || delta <= -10) {
    deltaStringDigits = 1;
  }

  let deltaString = delta.toFixed(deltaStringDigits);
  if (delta > 0) {
    deltaString = `+${deltaString}`;
  } else if (delta === 0) {
    deltaString = ` ${deltaString}`;
    badgeClass = "text-bg-primary";
  }

  return <span className={["currency-delta", "badge", badgeClass].join(" ")}>{deltaString}</span>;
};

export default function CurrencyExchangeRates(): React.JSX.Element {
  const [rates, setRates] = useState<CurrencyExchangeRate[]>([]);
  const [selectedPair, setSelectedPair] = useState(CURRENCY_PAIRS[0]);
  const page = 1;

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfCurrencyExchangeRatesForSpecifiedPair({
        from_currency: DEFAULT_CURRENCY,
        to_currency: selectedPair as Currency,
        page,
      });
      setRates(response.data.items);
    })();
  }, [selectedPair]);

  let tempPreviousItem: Nullish<CurrencyExchangeRate>;
  const rows = rates.map((item, index) => {
    tempPreviousItem = rates[index + 1];
    const digitsCount = item.rate > 1000 ? 2 : 4;
    return (
      <tr key={item.id}>
        <td>{displayDate(item.date, true)}</td>
        <td>{item.rate.toFixed(digitsCount)}</td>
        <td>{tempPreviousItem ? formatDelta(item, tempPreviousItem) : "-"}</td>
      </tr>
    );
  });

  // remove last item from array, because it doesn't have delta
  rows.splice(-1);

  const currencyOptions = CURRENCY_PAIRS.map((item, index) => (
    <span
      key={item}
      className={["me-2", "badge", item === selectedPair ? "text-bg-success" : "text-bg-primary"].join(" ")}
      role="button"
      tabIndex={index}
      onClick={() => setSelectedPair(item)}
      data-test="cer-switch"
    >
      {DEFAULT_CURRENCY} - {item}
    </span>
  ));

  return (
    <>
      <h3>Currency Exchange Rates</h3>

      {currencyOptions}

      <table
        className="mt-2 table table-responsive table-bordered table-striped table-sm"
        data-test="cer-table"
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>Rate</th>
            <th>Delta</th>
          </tr>
        </thead>
        <tbody>
          {rates.length ? (
            rows
          ) : (
            <tr>
              <td colSpan={3}>No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
